<!--
 * @Author: John
 * @Date: 2022-02-22 14:05:50
 * @LastEditTime: 2022-05-06 20:52:18
 * @LastEditors: John
 * @Description: 关于我们
 * @FilePath: \jjms_ui\src\views\site\help.vue
 * @Copyright John
-->
<template>
    <div style="padding: 0 300px;">
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="工具收费吗？是否安全？" name="21">
                <p>
                    工具完全免费使用，服务器性能一般，请大家合理利用资源，让更多人享受到便利~
                </p>
                <p>
                    本工具仅作为定投参考和收益统计用，没有交易功能，无需登录交易账户，不会影响资金安全，可以放心用~
                </p>
            </el-collapse-item>
            <el-collapse-item title="关于注册账号" name="1">
                <p>
                    账号注册时有几项必须要填写，分别是：昵称、用户名、密码、重复密码和邮箱，其他项可以视情况填写。 <br>
                    其中，用户名和密码用来登录系统，一定要记好；邮箱用来接收定投提醒邮件，一定要填写自己的有效邮箱地址。 <br>
                    另外，手机号目前没有用到，不过建议大家填写自己常用的手机号，不排除以后增加短信提醒功能哈~
                </p>
            </el-collapse-item>
            <el-collapse-item title="如何添加定投计划？" name="2">
                登录系统后，点击左侧菜单【定投管理】 -> 【添加定投】即可添加定投计划 <br>
                基金名称、基金代码： 可以输入基金名称模糊查找或者输入精确的基金代码都可以，两个是联动的，任意输入一个，系统会自动输入另一个 <br>
                定投金额：填写自己每期要定投的金额 <br>
                申购费率、赎回费率：需要去自己常用的基金交易平台查询，具体下面会说 <br>
                定投周期：选择适合自己的定投周期，目前系统支持按周或按月定投 <br>
                下次定投日期： 选择下次要定投的日期，之后系统会按照选择的定投周期，自动往后推算下下次定投日期，自动更新该值
            </el-collapse-item>
            <el-collapse-item title="从哪里能查到基金的申购、赎回费率？" name="3">
                基金交易分为场内交易和场外交易，场内交易需要开通股票交易账号，通过证券公司App进行交易，交易费用一般较低，但是可操作的基金种类有限，操作金额和时间也很不灵活。 <br>
                像我们平台通过支付宝、微信、天天基金网等平台进行的基金申购、赎回都属于场外交易，场外交易和场内交易的区别请自行上网了解，这里不做讨论。 <br>
                以我使用的支付宝为例，点开任意一个基金的详情页，往下滑，即可看到【交易规则】，点击买入、卖出规则即可查看详情，这里放一张图：<br>
                <img src="@/assets/img/rate-example.jpg" alt="基金交易规则示例图">

            </el-collapse-item>
            <el-collapse-item title="选择按周定投还是按月定投？" name="4">
                这个问题之前我看过一个调查报告，两种方式貌似没啥差别，看自己喜好即可。
            </el-collapse-item>
            <el-collapse-item title="定投时间选择周几比较好？" name="5">
                只要在周四下午3点前（法定节假日的话要放假前第二天下午3点前）完成申购或赎回都可以，我是选择周四，据说周四跌的概率大一些，方便抄底（从我的经验来看好像纯属瞎说，哈哈）
            </el-collapse-item>
            <el-collapse-item title="每天的收益数据啥时候更新？" name="6">
                系统中收益数据在T+1日上午8点更新（本系统中计算的收益只是为了统计，真实的收益数据还是以你购买基金的平台为准），一般来说只要定投计划中的申购、赎回费用填写正确，系统中计算的
                份额和收益应该和基金平台一致，如果不一致请检查自己定投计划中的费率。 <br>
                还有一种情况，如果你用系统之前已经持有某个基金了，在系统中第一次录入交易记录时，需要填写之前累计持有的份额，如果没填写，也会导致系统收益计算不准。
            </el-collapse-item>
            <el-collapse-item title="交易记录录入需要注意什么？" name="7">
                录入交易记录时一共有下面几个数据需要我们填写：<br>
                <ul>
                    <li><b>基金名称</b>， 只能选择自己定投计划中的基金</li>
                    <li><b>交易时间</b>， 精确到小时，分秒无所谓，如果是刚交易完，直接点“此刻”就好</li>
                    <li><b>交易类型</b>，选择本次交易是申购还是赎回</li>
                    <li><b>已累计投入金额</b>，填写自己在用本系统前该基金已投入多少钱，如果不知道和当前持仓总市值填一样即可</li>
                    <li><b>已持有份额</b>，填写自己在用本系统前该基金已持仓份额，如果没有填0</li>
                    <li><b>当前持仓总市值</b>，填写自己在用本系统前该基金目前持仓市值（从基金平台上可看到）</li>
                </ul>
                <span style="color: red;">后面三个只有第一次录入交易记录时自己已经有持仓才填写（也就是说用系统前已经有买这个基金）</span>
            </el-collapse-item>
            <el-collapse-item title="为啥我录入的交易记录，系统自动份额确认时和第三方平台上确认的份额不一致？" name="8">
                这种情况只有一个原因就是定投计划中填写的申购、赎回费率不正确或压根儿没填写，去定投计划中修改费率，下次定投份额确认就会正确。 <br>
                <span style="color: red;">注：之前确认错份额的交易记录可以在下次确认份额前手动编辑交易记录将份额和累计总份额修改正确即可不影响下次计算累计份额。</span>
            </el-collapse-item>
            <el-collapse-item title="怎么查看收益统计报告？" name="9">
                登录系统后，在【统计数据】-【收益分析】中即可查看各种维度的收益统计数据，系统支持按基金名称和统计维度两个方面统计收益。 基金名称如果不填代表统计本人持仓的所有基金<br>
                比如，要查看我今年4月份的收益数据，基金名称置空，统计维度选择按月汇总即可。 <br>
                <img src="@/assets/img/statistic-example.png" alt="收益统计示例">
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<script>
export default {
    name: 'about',
    data() {
        return {
            activeNames: []
        }
    },
    methods: {
        handleChange(val) {
            console.log(val)
        }
    }
}
</script>
